import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Prefooter from '../components/PreFooter/Prefooter'
import Footer from '../components/Footer/Footer'
import GrasUnderline from '../components/P/GrasUnderline'
import Center from '../components/Center'
import PgrasCenter from '../components/P/PgrasCenter'
import Boutton from '../components/Boutton/Boutton'

const Tarifs = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap">
      {' '}
      <Box width={[1, 1]} py={[2, 3, 5]}>
        <Center>
          <p>
            <GrasUnderline>Tarifs hyménoptères:</GrasUnderline>
            <br />
            <PgrasCenter>à partir de 80&euro; </PgrasCenter>
            <br />
            <GrasUnderline>Tarifs chenilles processionnaires:</GrasUnderline>
            <br />
            <PgrasCenter>en fonction de la surface à traiter.</PgrasCenter>
            <br />
            Ces tarifs comprennent la main d'oeuvre, le matériel et les consommables.
            <br />
            {' '}
            Le trajet est également inclus pour les communes situées à - de 50km (zone 1).
            <br />
            Pour les autres communes, un supplément vous sera demandé.
            <br />
            {' '}
            Cliquer ci-dessous pour connaître la zone tarifaire de votre commune.
            <br />
            <br />
            <br />
          </p>
          <Boutton as={Link} to="/zones-tarifaires">
            Zones tarifaires
          </Boutton>
        </Center>
      </Box>
    </Flex>

    <Prefooter />
    <Footer />
  </Layout>
)

export default Tarifs
