import React from 'react'
import styled from 'styled-components'

import { Flex, Box } from 'reflexbox'

const Prefoot = styled.span`
  text-align: left;
  overflow: hidden;
  color: #000000;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
`

const Prefooter = () => (
  <Flex flexWrap="wrap">
    <Box width={[1]} p={[2, 3, 4]} py={[4, 3, 4]}>
      <Prefoot>
        ABC Guêpes Destruction de nid de guêpes, de frelons, de frelons
        asiatiques.Traitement contre les chenilles processionnaires. Seine et
        Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours
        Chelles Fontainebleau Meaux Sens Corbeil Essonnes Evry
      </Prefoot>
    </Box>
  </Flex>
)

export default Prefooter
