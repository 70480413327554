import React from 'react'

import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import { Card } from 'rebass'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Heading from '../components/Heading/Heading'
import Center from '../components/Center'
import Layout from '../components/layout'
import Seo from '../components/seo'

import Footer from '../components/Footer/Footer'
import Prefooter from '../components/PreFooter/Prefooter'
import Boutton from '../components/Boutton/Boutton'

const Padding = styled.div`
  padding-top: 4vh;
`
const Galerie = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap">
      {' '}
      <Box width={[1, 1 / 3]} p={[3, 3, 4]}>
        <Card as={Link} to="/galerie-guepes">
          <StaticImage
            width={800}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Hyménoptères"
            src="../images/p7s7.jpg"
          />
          <Padding />
          <Center>
            <Boutton>Galerie Hyménoptères</Boutton>
          </Center>
        </Card>
      </Box>
      <Box width={[1, 1 / 3]} p={[3, 3, 4]}>
        <Card as={Link} to="/galerie-chenilles-processionnaires">
          <StaticImage
            width={800}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            src="../images/p17s3.jpg"
            alt="chenilles processionnaires"
          />
          <Padding />
          <Center>
            <Boutton>Galerie chenilles processionnaires</Boutton>
          </Center>
        </Card>
      </Box>
      <Box width={[1, 1 / 3]} p={[3, 3, 4]}>
        <Card as={Link} to="/video">
          <StaticImage
            width={800}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="video"
            src="../images/wally-holden-ei03zf89hRs-unsplash.jpg"
          />
          <Padding />
          <Center>
            <Boutton>Vidéos</Boutton>
          </Center>
        </Card>
      </Box>
    </Flex>

    <Prefooter />
    <Footer />
  </Layout>
)

export default Galerie
