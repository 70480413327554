import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Prefooter from '../components/PreFooter/Prefooter'
import Footer from '../components/Footer/Footer'
import Center from '../components/Center'
import GrasUnderline from '../components/P/GrasUnderline'
import Underline from '../components/P/Underline'
import Boutton from '../components/Boutton/Boutton'

const PresentationChenillesProcessionnaires = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap" alignItems="center">
      {' '}
      <Box width={[1, 1]} p={[2, 3, 5]}>
        <p>
          <Center>
            <GrasUnderline>2 types:</GrasUnderline>
            <br />
            les chenilles processionnaires du pin et les chenilles
            processionnaires du chêne
            <br />
            <br />
            <GrasUnderline>
              Dangers:
              <br />
            </GrasUnderline>
            Si leurs longs poils sont inoffensifs, ces chenilles projettent dans
            l'air de minuscules poils très urticants qui peuvent provoquer
            d'importantes réactions allergiques : démangeaisons, inflammations
            (au niveau des mains, du cou, du visage) mais aussi des troubles
            oculaires ou respiratoires. Il est également dangereux de manipuler
            un nid même vide. Le danger est particulièrement important pour les
            animaux domestiques : un chien atteint à la langue (qu'il peut avoir
            utilisé pour lécher les démangeaisons sur son corps) s'il n'est pas
            traité rapidement risque la nécrose de la langue.
            <br />
            <br />
            <GrasUnderline>Moyens de lutte: </GrasUnderline>
            <br />
            <Underline>Échenillage: </Underline> <br />
            Retrait des nids puis incinération des déchets. <br />
            Période: de novembre à avril pour le pin et de mai à juillet pour le
            chêne
            <Underline>Piégeage: </Underline> <br />
            Piège placé pendant la procession afin de récupérer les chenilles.
            Il y également la possibilité de piégé les papillons mâles grâce à
            des pièges à phéromone de juin à septembre. <br /> Période: de
            janvier à juin
            <Underline>Traitements: </Underline> <br />2 sortes, un biologique
            et un chimique. En général le traitement biologique est utilisé du
            fait de sa non-toxicité. Il est donc sans danger pour les animaux,
            les auxiliaires, les insectes pollinisateurs, les organismes
            aquatiques, les poissons, les végétaux et les utilisateurs. <br />
            Période: de septembre à décembre pour le pin et d'avril à juin pour
            le chêne
          </Center>{' '}
          <br /> <br />
        </p>
        <Center>
          <Boutton as={Link} to="/presentation">
            Hyménoptères
          </Boutton>
        </Center>
      </Box>
      <Box width={[1, 1 / 4]} p={[2, 3, 5]}>
        <div className="imgcenter">
          <StaticImage
            height={600}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            src="../images/p16s11002.jpg"
            alt="A dinosaur"
          />
        </div>
      </Box>
      <Box width={[1, 1 / 4]} p={[2, 3, 5]}>
        <div className="imgcenter">
          <StaticImage
            height={600}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            src="../images/p16s11003.jpg"
            alt="A dinosaur"
          />
        </div>
      </Box>
      <Box width={[1, 1 / 4]} p={[2, 3, 5]}>
        <div className="imgcenter">
          <StaticImage
            height={600}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            src="../images/p16s11005.jpg"
            alt="A dinosaur"
          />
        </div>
      </Box>{' '}
      <Box width={[1, 1 / 4]} p={[2, 3, 5]}>
        <div className="imgcenter">
          <StaticImage
            height={600}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            src="../images/p16s11004.jpg"
            alt="A dinosaur"
          />
        </div>
      </Box>
    </Flex>

    <Prefooter />
    <Footer />
  </Layout>
)

export default PresentationChenillesProcessionnaires
