import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import { StaticImage } from 'gatsby-plugin-image'
import Gras from '../P/Gras'

const HeadingH1 = styled.h1`
  text-align: center;
  font-style: normal;
  font-family: 'I AM A PLAYER';
  font-weight: normal;
  font-style: normal;
  font-weight: normal;
  font-weight: bold;
  padding: 3vh 2vw 1vh 2vw;
  margin: 0;
  color: #e6332a;
  background-color: transparent;
  text-decoration: none;
  font-size: 1.9rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`
const Heading2 = styled.span`
  text-align: center;
  font-style: normal;
  font-family: 'I AM A PLAYER';
  font-weight: normal;
  font-style: normal;
  font-weight: normal;
  font-weight: bold;
  padding: 3vh 2vw 1vh 2vw;
  margin: 0;
  color: #e6332a;
  background-color: transparent;
  text-decoration: none;
  font-size: 2.3rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`
const HeadingPara = styled.div`
  font-size: 10pt;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  background-color: transparent;
  text-decoration: none;
`
const WrapGuepe = styled.div`
  display: block;
  margin: auto;

  @media (max-width: 768px) {
    display: none;
  }
`
const Heading = () => (
  <Flex flexWrap="wrap" bg="#42e2b8">
    <WrapGuepe>
      <Box width={[1, 2 / 6]} pt={[3, 3, 4]}>
        <StaticImage
          src="../../images/logo.png"
          alt=" Logo Abc guepes"
          placeholder="blurred"
          layout="fixed"
          width={200}
        />
      </Box>
    </WrapGuepe>
    <Box width={[1, 4 / 6]} pt={[3, 3, 4]}>
      <HeadingH1>
        <Heading2> ABC Guêpes</Heading2>
        <br />
        Le spécialiste, depuis plus de 20 ans, de la destruction de nids de
        guêpes, de frelons et de frelons asiatiques en ÎLE DE FRANCE
        <br /> ( Seine et Marne 77 Essonne 91 Val de Marne 94 Seine Saint Denis
        93 Paris 75 )
      </HeadingH1>
    </Box>
    <WrapGuepe>
      <Box width={[1, 2 / 6]} pt={[3, 3, 4]}>
        <StaticImage
          src="../../images/logo.png"
          alt="A Logo"
          placeholder="blurred"
          layout="fixed"
          width={200}
        />
      </Box>
    </WrapGuepe>
    <Box width={[1, 1]} p={[3, 3, 4]}>
      <HeadingPara>
        Nous sommes une
        <Gras> entreprise professionnelle </Gras>
        et
        <Gras> spécialisée </Gras>
        dans la
        <Gras> désinsectisation </Gras>
        et la
        <Gras> destruction </Gras>
        des&nbsp;
        <Gras>nids d’hyménoptères </Gras>
        tels que les
        <Gras> abeilles </Gras>, les
        <Gras> guêpes </Gras>, les
        <Gras> frelons </Gras>
        et les
        <Gras> frelons asiatiques </Gras>
        .
        <br />
      </HeadingPara>
    </Box>
  </Flex>
)

export default Heading
