import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'
import {
  TwitterSquare,
  GooglePlusSquare,
  FacebookSquare,
} from '@styled-icons/fa-brands'

const Facebook = styled(FacebookSquare)`
  color: blue;
  width: 60px;
  height: auto;
  margin: 10px;
`
const Twitter = styled(TwitterSquare)`
  color: #00acee;
  width: 60px;
  height: auto;
  margin: 10px;
`
const Google = styled(GooglePlusSquare)`
  color: #db4a39;
  width: 60px;
  height: auto;
  margin: 10px;
`

const SuperBold = styled.p`
  font-weight: bold;

  color: #e6332a;
  font-size: 2.1rem;
  padding: 0;
  margin: 0;
`
const CenterImg = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
`
const Center = styled.div`
  text-align: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
`
const Center2 = styled.div`
  text-align: center;
  padding-top: 3.5vh;
  padding-bottom: 2vh;
`
const Footer = () => (
  <>
    <Flex flexWrap="wrap" bg="#42e2b8">
      <Box width={[1, 1 / 3]} pt={[2, 3]} order={[3, 1]}>
        <Center>
          Egalement sur
          <CenterImg>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/pages/ABC-GUEPES/202604103125626"
            >
              <Facebook />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/ABCGupes"
            >
              <Twitter />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://plus.google.com/u/0/b/107576137126427675082/107576137126427675082/posts"
            >
              <Google />
            </a>
          </CenterImg>
        </Center>
      </Box>
      <Box width={[1, 1 / 3]} pt={[2, 4]} order={[2, 2]}>
        <Center>
          ABC Guêpes
          <br />
          43 allée de la comtesse d'Aulnoy 77820
          <br />
          LE CHATELET EN BRIE
          <br />
          06.31.26.63.84
          <br />
          <a href="mailto:sebastien@abc-guepes.com">sebastien@abc-guepes.com</a>
        </Center>
      </Box>
      <Box width={[1, 1 / 3]} pt={[2, 3]} order={[1, 3]}>
        <SuperBold>
          <Center2>
            24H/24
            <br />
            7J/7
          </Center2>
        </SuperBold>
      </Box>
    </Flex>
  </>
)

export default Footer
