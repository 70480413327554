import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Prefooter from '../components/PreFooter/Prefooter'
import Footer from '../components/Footer/Footer'
import Center from '../components/Center'
import Prouge from '../components/P/Prouge'

const LiensDivers = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap">
      {' '}
      <Box width={[1, 1]} py={[2, 3, 5]}>
        <Center>
          <Prouge>Mariage cadillac 77</Prouge>
          <p>
            Location de Cadillac Deville cabriolet rose de 1968 pour les
            mariages, anniversaires, parades, tournages, expositions ...
          </p>
          <StaticImage
            width={200}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Mariage cadillac"
            src="../images/p15b18Ov.jpg"
          />
          <br />
          <a
            href="http://www.mariagecadillac77.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.mariagecadillac77.fr
          </a>
        </Center>
      </Box>{' '}
      <Box width={[1, 1]} py={[2, 3, 3]}>
        <Center>
          <Prouge>ABC Guêpes</Prouge>
          <StaticImage
            width={200}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="ABC Guêpes"
            src="../images/logo.png"
          />
          <br />
          <a
            href="https://www.abc-guepes.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.abc-guepes.com{' '}
          </a>
          <br />

          <a
            href="http://www.destruction-nid-de-guepes-frelons.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.destruction-nid-de-guepes-frelons.fr
          </a>
          <br />
          <a
            href="http://www.nid-guepes-frelons-essonne-91.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.nid-guepes-frelons-essonne-91.fr
          </a>
          <br />
          <a
            href="http://www.nid-guepes-frelons-seine-saint-denis-93.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.nid-guepes-frelons-seine-saint-denis-93.fr
          </a>
          <br />
          <a
            href="www.nid-guepes-frelons-val-de-marne-94.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.nid-guepes-frelons-val-de-marne-94.fr
          </a>
        </Center>
      </Box>
    </Flex>

    <Prefooter />
    <Footer />
  </Layout>
)

export default LiensDivers
