import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
    background-color: #fff;line-height: 1.2;
  }
  .navbar{
      width: 100%;
      max-width: 100%;
      top:0;
    background-color: #fff;
}
  .imgcenter img{ display: block;
  margin-left: auto;
  margin-right: auto;}
.frelon img{display: block;
  margin-left: auto;
  margin-right: auto;}
`

export default GlobalStyle
