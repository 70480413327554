import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import { StaticImage } from 'gatsby-plugin-image'
import Zoom from 'react-medium-image-zoom'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Prefooter from '../components/PreFooter/Prefooter'
import Footer from '../components/Footer/Footer'
import 'react-medium-image-zoom/dist/styles.css'
import Center from '../components/Center'
import Boutton from '../components/Boutton/Boutton'

const GalerieGuepesDeux = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap" className="frelon">
      {' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s4.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de bourdons
              "
              width={400}
              height={300}
            />
            <h1>Nid de bourdons</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s1.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes dans un tiroir
            "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes dans un tiroir</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s6.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes dans un tiroir
              "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes dans un tiroir</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s5.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes sur un mur
              "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes sur un mur</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s2.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes dans une haie
              "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes dans une haie</h1>
          </Center>
        </Zoom>
      </Box>{' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s3.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes
              "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s7.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Frelon"
              width={400}
              height={300}
            />
            <h1>Frelon</h1>
          </Center>
        </Zoom>
      </Box>{' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s0.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de frelons
              "
              width={400}
              height={300}
            />
            <h1>Nid de frelons</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s10.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes
              "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes</h1>
          </Center>
        </Zoom>
      </Box>{' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s16.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes
              "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s18.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes sous toiture
              "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes sous toiture</h1>
          </Center>
        </Zoom>
      </Box>{' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p18s17.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Nid de guêpes sous toiture
              "
              width={400}
              height={300}
            />
            <h1>Nid de guêpes sous toiture</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1]} p={[2, 2, 2]}>
        <Center>
          <Boutton as={Link} to="/galerie-guepes">
            Retour
          </Boutton>
        </Center>
      </Box>
    </Flex>

    <Prefooter />
    <Footer />
  </Layout>
)

export default GalerieGuepesDeux
