import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import { StaticImage } from 'gatsby-plugin-image'
import Zoom from 'react-medium-image-zoom'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Prefooter from '../components/PreFooter/Prefooter'
import Footer from '../components/Footer/Footer'
import 'react-medium-image-zoom/dist/styles.css'
import Center from '../components/Center'
import Boutton from '../components/Boutton/Boutton'

const GalerieGuepes = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap" className="frelon">
      {' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/IMG_0304.27.jpg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Tenue d'intervention</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <StaticImage
            src="../images/p7s1.jpeg"
            quality={100}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Tenue d'intervention"
            width={400}
            height={300}
          />
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s6.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Frelon asiatique</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s5.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Nid d'hyménoptères dans un conduit de cheminée</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s2.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Nid de guêpes en terre</h1>
          </Center>
        </Zoom>
      </Box>{' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s3.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Nid de frelons</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s7-1.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Frelon</h1>
          </Center>
        </Zoom>
      </Box>{' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s0.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Nid de frelons dans un tronc d'arbre</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s10.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Alvéoles d'un nid de frelons</h1>
          </Center>
        </Zoom>
      </Box>{' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s18.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Nid de frelons dans un arbre</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s20.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Nid de frelons</h1>
          </Center>
        </Zoom>
      </Box>{' '}
      <Box width={[1, 1 / 2]} p={[2, 3, 5]}>
        <Zoom zoomMargin={40}>
          <Center>
            <StaticImage
              src="../images/p7s19.jpeg"
              quality={100}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Tenue d'intervention"
              width={400}
              height={300}
            />
            <h1>Nid de guêpes</h1>
          </Center>
        </Zoom>
      </Box>
      <Box width={[1]} p={[2, 2, 2]}>
        <Center>
          <Boutton as={Link} to="/galerie-guepes-2">
            Suivant
          </Boutton>
        </Center>
      </Box>
    </Flex>

    <Prefooter />
    <Footer />
  </Layout>
)

export default GalerieGuepes
