import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const LogoWrap = styled(StaticImage)`
  padding-left: 6vw;
  padding-top: 10px;

  @media (max-width: 768px) and (orientation: landscape) {
    width: 80px !important;
  }
`
const Logo = () => (
  <LogoWrap as={Link} to="/">
    <StaticImage
      src="../../images/logo.svg"
      alt="A Logo"
      placeholder="blurred"
      layout="fixed"
      width={120}
    />
  </LogoWrap>
)

export default Logo
