import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Flex, Box } from 'reflexbox'
import Zoom from 'react-medium-image-zoom'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Footer from '../components/Footer/Footer'
import Prefooter from '../components/PreFooter/Prefooter'
import 'react-medium-image-zoom/dist/styles.css'
import Prouge from '../components/P/Prouge'
import Center from '../components/Center'
import Pcenter from '../components/P/Pcenter'
import Gras from '../components/P/Gras'

const PredPad = styled(Prouge)`
  padding-top: 2vh;
`
const TittleH2 = styled.h2`
  font-weight: bold;
  font-size: 1.6em;
  color: #e6332a;
  padding-top: 2vh;
`
const TittleH1 = styled.h1`
  font-weight: bold;
  font-size: 2.3em;
  padding-top: 2vh;
  color: #e6332a;
`
const TittleH3 = styled.h3`
  font-weight: bold;
  font-size: 1.3em;
  color: #e6332a;
  padding-top: 1.6vh;
`
const LiNoBullet = styled.li`
  list-style-type: none;
  padding: 10px 0;
`
const Para = styled.p`
  line-height: 1.4;
`
const Ul = styled.ul`
  text-align: left;
`
const IndexPage = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap" px={[4, 0, 0]}>
      <Box width={[1, 3 / 12]} px={[2, 3, 4]} order={[6, 1]}>
        <Zoom zoomMargin={40}>
          <StaticImage
            src="../images/edition6519563152455660792.jpg"
            quality={100}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Certification ABC Guepes"
            width={900}
          />
        </Zoom>

        <PredPad>Certificat Biocide</PredPad>
      </Box>
      <Box width={[1, 6 / 12]} px={[2, 3, 4]} pt={[2, 5]} order={[1, 2]}>
        <Center>
          <TittleH1>Piqûres de rappel</TittleH1>
          {' '}
          <TittleH2>Ce que vous devez retenir</TittleH2>
        </Center>
      </Box>

      <Box width={[1, 3 / 12]} px={[2, 3, 4]} order={[7, 3]}>
        <Zoom>
          <StaticImage
            width={900}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Nid de guepes"
            src="../images/nid-de-guepes.jpg"
          />
        </Zoom>
      </Box>
      <Box width={[1, 1]} px={[2, 3, 5]} order={[4, 4]}>
        <Pcenter>
          A l’arrivée du
          <Gras>&nbsp;printemps&nbsp;</Gras>
          et des beaux jours, certains insectes tels que les
          <Gras>&nbsp;guêpes</Gras>
          , les
          <Gras>&nbsp;frelons&nbsp;</Gras>
          et les
          <Gras>&nbsp;frelons asiatiques&nbsp;</Gras>
          choisissent de s’installer à votre domicile ou dans le voisinage proche. Ce sont pour eux
          la saison idéale pour
          <Gras>&nbsp;envahir&nbsp;</Gras>
          vos foyers. Attirés entre autres par la
          <Gras>&nbsp;nourriture&nbsp;</Gras>
          consommée par l’être humain, par le
          <Gras>&nbsp;sucre&nbsp;</Gras>
          ou encore par
          <Gras>&nbsp;l’eau&nbsp;</Gras>
          , ces nuisibles n’hésitent pas à créer leur nid dans des
          endroits divers et variés et plus ou moins accessibles à l’homme. Il n’est donc pas
          étonnant de découvrir des nids dans des
          <Gras>&nbsp;arbres fruitiers&nbsp;</Gras>
          , sous les
          <Gras>&nbsp;tuiles&nbsp;</Gras>
          de vos toits ou dans une cheminée. D’une manière générale, les
          <Gras>&nbsp;hyménoptères&nbsp;</Gras>
          ne sont pas naturellement dangereux. Ces insectes ne piquent que pour se
          <Gras>&nbsp;défendre&nbsp;</Gras>
          lorsqu’ils se sentent menacés. Les
          <Gras>&nbsp;guêpes</Gras>
          , les
          <Gras>&nbsp;frelons</Gras>
          , les
          <Gras>&nbsp;abeilles&nbsp;</Gras>
          et piquent grâce à leur dard ou aiguillon propageant un venin. Seuls les hyménoptères
          <Gras>&nbsp;femelles&nbsp;</Gras>
          piquent car les
          <Gras>&nbsp;hyménoptères mâles&nbsp;</Gras>
          ne possèdent pas d’appareils de défense.
        </Pcenter>

        <Center>
          <TittleH2>Le danger et les conséquences sur l’homme</TittleH2>

          <Para>
            Bien que de petite taille, ces insectes ont tout de même des
            <Gras>&nbsp;répercussions&nbsp;</Gras>
            notables à ne pas prendre à la légère. Il faut savoir que les nids se développent tout
            au long de l’été et peuvent grossir de manière rapide et engendrer des
            <Gras>&nbsp;dégâts conséquents&nbsp;</Gras>
            suivant où le nid a été construit. Sachez qu’un nid de guêpes en plein développement
            peut contenir une
            <Gras>&nbsp;colonie&nbsp;</Gras>
            d’environ 5000 guêpes. Une
            <Gras>&nbsp;toiture</Gras>
            {' '}
            , des
            <Gras>&nbsp;tuiles&nbsp;</Gras>
            ou des
            <Gras>&nbsp;combles&nbsp;</Gras>
            sont des lieux particulièrement touchés où le
            <Gras>&nbsp;risque&nbsp;</Gras>
            est majeur.
            <Gras>&nbsp;L’isolation&nbsp;</Gras>
            peut être endommagée et les insectes peuvent donc se faufiler à l’intérieur de votre
            habitation.
          </Para>
          <Para>
            De plus, une piqûre de guêpe, de frelon ou d’abeille est douloureuse et venimeuse. Une
            <Gras>&nbsp;piqûre mal placée&nbsp;</Gras>
            (gorge, cou, paupières, bouche) peut non seulement entraîner un
            <Gras>&nbsp;oedème&nbsp;</Gras>
            volumineux mais provoquer une
            <Gras>&nbsp;difficulté respiratoire&nbsp;</Gras>
            et engendrer un
            <Gras>&nbsp;risque vital&nbsp;</Gras>
            . Les
            <Gras>&nbsp;piqûres multiples&nbsp;</Gras>
            sont également très
            <Gras>&nbsp;dangereuses&nbsp;</Gras>
            (au-delà de 20 chez l’adulte et 4 ou 5 chez un enfant) car la quantité de venin absorbée
            est importante (risques de vomissements, maux de tête, vertiges etc). Enfin si vous êtes
            allergique, une seule piqûre de
            <Gras>&nbsp;guêpe</Gras>
            , de
            <Gras>&nbsp;frelon&nbsp;</Gras>
            ou d’
            <Gras>&nbsp;abeille&nbsp;</Gras>
            <Gras>&nbsp;au choc anaphylactique.&nbsp;</Gras>
            Pour votre information, sachez qu’il est très rare que les contrats d’habitation
            classiques prennent en charge l'infestation d’insectes. Pour voir à quoi ressemble ces
            différents types d’insectes et vous faire une idée des nids existants, rendez-vous sur
            notre page&nbsp;
            <Link to="/galerie">galerie</Link>
          </Para>
          <TittleH2>Ne tentez surtout pas de détruire vous-même des nids !</TittleH2>
          <Para>
            Il est fortement conseillé de rester éloigné et de
            <Gras>&nbsp;ne pas détruire&nbsp;</Gras>
            par
            <Gras>&nbsp;ses propres moyens&nbsp;</Gras>
            un
            <Gras>&nbsp;nid de guêpes&nbsp;</Gras>
            ou de
            <Gras>&nbsp;frelons / frelons asiatiques&nbsp;</Gras>
            . Cela aura comme conséquence de
            les exciter davantage et de provoquer une
            <Gras>&nbsp;piqûre dangereuse&nbsp;</Gras>
            . Nous vous recommandons donc d’appeler un
            professionnel dès que vous constatez un nid près de chez vous.
          </Para>
          <TittleH3>Questions / Réponses et fausses idées...</TittleH3>
        </Center>
        <Ul>
          <li>
            Porter des
            <Gras>&nbsp;vêtements longs&nbsp;</Gras>
            et
            <Gras>&nbsp;épais&nbsp;</Gras>
            couvrant l’ensemble du corps suffit pour être protégé ?
          </li>
          <LiNoBullet>
            &rArr;&nbsp;Réponse : Pas du tout, tout comme porter des
            <Gras>&nbsp;lunettes de protection&nbsp;</Gras>
            ne vous protégera pas d’une attaque des insectes
          </LiNoBullet>
          <li>
            Brûler un nid avec une torche à l'extrémité d’un bâton ou d’une barre métallique peut
            être envisageable.
          </li>
          <LiNoBullet>
            &rArr;&nbsp;FAUX : Ne pas brûler,
            <Gras>&nbsp;noyer&nbsp;</Gras>
            ou
            <Gras>&nbsp;frapper le nid&nbsp;</Gras>
            : toutes ces méthodes sont
            <Gras>&nbsp;inefficaces&nbsp;</Gras>
            pour
            <Gras>&nbsp;éradiquer&nbsp;</Gras>
            les frelons ou les guêpes. Vous pourriez provoquer un incendie, une inondation de
            l’endroit concerné et pire énerver les guêpes. Si elles se sentent en danger, elles
            deviennent agressives et multiplient les attaques ce qui peut être
            <Gras>&nbsp;très dangereux&nbsp;</Gras>
            si vous êtes allergique aux piqûres et que vous tombez en
            <Gras>&nbsp;état de choc anaphylactique</Gras>
            .
          </LiNoBullet>
          <li>
            Les guêpes sont beaucoup plus actives en journée, il vaut donc mieux
            <Gras>&nbsp;agir en soirée</Gras>
            ?
          </li>

          <LiNoBullet>
            &rArr;&nbsp;Réponse : FAUX; que vous soyez en pleine journée ou la nuit, un nid reste
            dangereux, elles ne seront pas pour autant moins agressives et vous ne serez donc pas
            plus efficace…
          </LiNoBullet>
          <li>Les nids d’abeilles sont-ils détruits par les professionnels ?</li>
          <LiNoBullet>
            &rArr;&nbsp;Réponse : Cela dépend, chez ABC-Guepes, nous travaillons avec des
            apiculteurs de la région ou à proximité de l’intervention de façon à protéger les nids
            d’abeilles en leur transmettant. Dès que c’est possible, nous détachons délicatement le
            nid d’abeille afin qu’ils puissent les récupérer. Un geste bienveillant et écologique
            qui participe au bien-être de la planète et de l’environnement !
          </LiNoBullet>
        </Ul>
        <Center>
          <TittleH3>Que faire en cas de piqûre ?</TittleH3>
          <Para>
            En cas de
            <Gras>&nbsp;piqûre de guêpes</Gras>
            , de
            <Gras>&nbsp;frelons&nbsp;</Gras>
            ou
            <Gras>&nbsp;d’abeilles</Gras>
            , il faut rapidement agir pour soulager la douleur. Il faut
            savoir que la guêpe ne laisse pas son dard lorsqu’elle pique contrairement à l’abeille.
            Pour extraire le venin injecté vous pouvez utiliser une
            <Gras>&nbsp;pompe à venin&nbsp;</Gras>
            ou une
            <Gras>&nbsp;source de chaleur intense&nbsp;</Gras>
            (briquet, sèche-cheveux). Pensez ensuite à
            <Gras>&nbsp;désinfecter la piqûre&nbsp;</Gras>
            avec un désinfectant local ou avec du vinaigre blanc dilué dans un peu d’eau. Notez que
            l’huile essentielle de lavande, l’oignon et l’ail peuvent également atténuer la douleur
            de la piqûre. Si la douleur est trop vive, si un gonflement ou des démangeaisons
            apparaissent, il est prudent d’
            <Gras>appeler les secours</Gras>
            .
          </Para>
          <TittleH1>Pourquoi faire appel à ABC GUEPES ?</TittleH1>
          <TittleH2>Professionnalisme</TittleH2>
          <Para>
            Fort d’une expérience de plus de 13 ans en tant que
            <Gras>&nbsp;sapeur pompier volontaire&nbsp;</Gras>
            puis
            <Gras>&nbsp;professionnel</Gras>
            , votre spécialiste a été formé à la
            <Gras>&nbsp;destruction de nids d’hyménoptères&nbsp;</Gras>
            et le
            <Gras>&nbsp;traitement des chenilles processionnaires&nbsp;</Gras>
            . ABC Guêpes
            intervient tant sur le domaine
            <Gras>&nbsp;privé&nbsp;</Gras>
            ou
            <Gras>&nbsp;public&nbsp;</Gras>
            qu’à votre domicile auprès des particuliers et des professionnels. Notre entreprise
            spécialisée intervient également pour
            <Gras>&nbsp;des interventions en hauteur&nbsp;</Gras>
            : arbre, cheminée, tuiles, toit,
            sous-toiture. Nous possédons le matériel adéquat en fonction du type de nid et de ses
            conditions d’accès. Nos
            <Gras>&nbsp;méthodes de destruction&nbsp;</Gras>
            nous permettent
            <Gras>&nbsp;d’éradiquer&nbsp;</Gras>
            ces insectes de manière
            <Gras>&nbsp;efficace&nbsp;</Gras>
            et
            <Gras>&nbsp;en toute sécurité</Gras>
            . Nous savons travailler dans des
            <Gras>&nbsp;petits espaces&nbsp;</Gras>
            et nous avons une
            <Gras>&nbsp;connaissance approfondie&nbsp;</Gras>
            sur ces nuisibles.
          </Para>
          <TittleH2>Sécurité et lutte préventive</TittleH2>
          <Para>
            Toute nos interventions sont effectuées en
            <Gras>&nbsp;toute sécurité&nbsp;</Gras>
            afin d’éviter le moindre danger et écarter tout risque pour vous et votre voisinage. Un
            <Gras>&nbsp;nid de guêpes&nbsp;</Gras>
            ou de
            <Gras>&nbsp;frelons&nbsp;</Gras>
            n’est pas toujours visible, il est possible certaines fois que le nid se trouve dans la
            maison d’à côté et que malgré tout, les guêpes entrent dans votre domicile attirées par
            de la nourriture ou par une source d’eau. Donc si vous avez le moindre doute, ABC Guêpes
            peut intervenir de
            <Gras>&nbsp;manière préventive</Gras>
            , procéder à un état des lieux et s’assurer qu’il
            n’y a aucune
            <Gras>&nbsp;invasion</Gras>
            , ni
            <Gras>&nbsp;prolifération&nbsp;</Gras>
            des nuisibles. Quand il y a un signalement avéré
            <Gras>&nbsp;d’essaims d’abeilles</Gras>
            , de
            <Gras>&nbsp;nids de frelons</Gras>
            , de
            <Gras>&nbsp;nids de guêpes&nbsp;</Gras>
            ou l’apparition de
            <Gras>&nbsp;chenilles processionnaires</Gras>
            , ABC Guêpes intervient rapidement et
            sécurise la zone d’intervention. L’équipe professionnelle a été formée et chaque
            technicien est équipé de vêtements de protection qui lui permettent d’éviter toute
            piqûre de guêpe ou de frelons et de s’approcher au plus près du lieu d’infestation. Nos
            intervenants utilisent uniquement des produits professionnels comme des
            <Gras>&nbsp;insecticides&nbsp;</Gras>
            et ce dans le respect de l’environnement. Ils permettent d’exterminer toute la colonie
            de guêpes et de frelons.
          </Para>
          <TittleH2>Intervention près de chez vous</TittleH2>
          <Para>
            Notre équipe intervient rapidement
            <Gras>&nbsp;tous les jours 24h/24h&nbsp;</Gras>
            et même
            <Gras>&nbsp;les jours fériés&nbsp;</Gras>
            . La prise en charge se fait dans la journée et
            nous vous garantissons un résultat optimal et
            <Gras>&nbsp;100% sécurisé</Gras>
            . En cas de necéssité de <Gras>&nbsp;seconde intervention</Gras> nous réintervenons
            gratuitement (sous réserve du client de bien suivre les consignes que nous lui 
            indiquons en fin d'intervention et du respect de la <Gras>&nbsp;période de 10j maximum de garantie</Gras> pour la demande de repasse).
            <Gras>&nbsp;100% sécurisé</Gras>
            . Nous nous déplaçons partout en
            <Gras>&nbsp;Seine et Marne&nbsp;</Gras>
            (77) mais aussi dans les départements frontaliers :
          </Para>
          <Ul>
            <li>
              <Gras>&nbsp;le Loiret (45)&nbsp;</Gras>
              : situé à une centaine de kilomètres au sud de
              Paris, nos spécialistes interviennent dans les quatres aires urbaines que sont Gien,
              Montargis, Orléans et Pithiviers
            </li>
            <li>
              <Gras>&nbsp;Paris&nbsp;</Gras>
              et sa banlieue (75) : des interventions dans toute la capitale et sa périphérie,
            </li>
            <li>
              l’
              <Gras>Yonne&nbsp;</Gras>
              (89) : département situé dans la région de la Bourgogne-Franche-Comté, nous nous
              déplaçons aussi bien dans la ville principale d’Auxerre que dans les communes voisines
              comme Avallon ou Migennes;
            </li>
            <li>
              l’
              <Gras>Essonne&nbsp;</Gras>
              (91) : au sud de Paris, nous programmons aussi bien des interventions dans les
              communes principales telles que Évry-Courcouronnes et Corbeil-Essonnes que dans les
              plus petites telles que Brétigny-sur-Orge ou Brunoy;
            </li>
            <li>
              <Gras>&nbsp;Seine Saint Denis&nbsp;</Gras>
              (93) : notre équipe de spécialistes intervient dans les trois arrondissements (Saint
              Denis, Bobigny et Raincy) de ce département situé au nord-est de l’agglomération
              parisienne;
            </li>
            <li>
              <Gras>&nbsp;Val de Marne&nbsp;</Gras>
              (94) : Vitry-sur-Seine, Créteil, Ivry-sur-Seine, Villejuif, Vincennes, Choisy-le-Roi
              ou encore Nogent-sur-Marne sont quelques exemples de villes de ce département de la
              banlieue parisienne où nous nous déplaçons.
            </li>
          </Ul>
          <TittleH2>Tarifs à partir de 80€</TittleH2>
          <Para>
            Nos tarifs et prestations comprennent la main-d'œuvre, le matériel et les consommables.
            Le déplacement est également inclus pour les communes situées à moins de 50 kilomètres
            (zone 1). Pour plus de détails sur les différentes zones tarifaires rendez-vous sur
            notre&nbsp;
            <Link to="/tarifs">page tarifs</Link>
            .
          </Para>
          <Para>
            Il se peut qu’en fonction du lieu d’infestation et du protocole utilisé par nos
            techniciens, si la destruction du nid de guêpes ou frelons est compliquée, le prix de
            l’intervention pourra avoir un coût supplémentaire.
          </Para>
          <Para>
            N’hésitez pas à nous contacter dès à présent par téléphone au
            <Gras>&nbsp;06.31.26.63.84</Gras>
            {' '}
            , par mail à&nbsp;
            <a href="mailto:sebastien@abc-guepes.com">sebastien@abc-guepes.com</a>
            <Gras>&nbsp;ou via notre page contact en cliquant&nbsp;</Gras>
            <Link to="/contact">ici</Link>
            &nbsp;pour demander un devis gratuit ! Rejoignez-nous également sur les réseaux sociaux
            sur notre page&nbsp;
            <a href="https://www.facebook.com/pages/ABC-GUEPES/202604103125626">Facebook</a>
            &nbsp;et sur&nbsp;
            <a href="https://twitter.com/ABCGupes">Twitter</a>
            .
            <br />
            N’attendez plus et faites appel à un professionnel pour vous débarrasser en toute
            sécurité de ces insectes.
          </Para>
        </Center>
      </Box>
    </Flex>
    <Prefooter />
    <Footer />
  </Layout>
)

export default IndexPage
