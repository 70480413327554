import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Prefooter from '../components/PreFooter/Prefooter'
import Footer from '../components/Footer/Footer'
import Video from '../components/video'
import Center from '../components/Center'
import Boutton from '../components/Boutton/Boutton'

const CenterVideo = styled.div`
  display: block;
  margin: 0 auto;
`
const VideoPage = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />

    <Flex flexWrap="wrap">
      <Box width={[1, 1]} py={[2, 3, 5]}>
        <Center>
          <h1>Vidéo</h1>
        </Center>
      </Box>
      <Box width={[1, 1 / 3]} p={[2, 3, 5]}>
        <Video
          videoSrcURL="https://www.youtube.com/embed/_iqX01UCC78"
          videoTitle="Nid de guêpes dans bambou à côté d’une cabane pour enfant détruit par ABC GUÊPES"
        />
      </Box>
      <Box width={[1, 1 / 3]} p={[2, 3, 5]}>
        <Video
          videoSrcURL="https://www.youtube.com/embed/X5W8O0GE_mI"
          videoTitle="Nid de frelons asiatiques détruit par ABC GUÊPES dans nichoir"
        />
      </Box>{' '}
      <Box width={[1, 1 / 3]} p={[2, 3, 5]}>
        <Video
          videoSrcURL="https://www.youtube.com/embed/v1R2DysXJwc"
          videoTitle="Nids de frelons dans nichoir traité par ABC GUÊPES"
        />
      </Box>
      <Box width={[1, 1 / 3]} p={[2, 3, 5]}>
        <Video
          videoSrcURL="https://www.youtube.com/embed/_axVXXbgmiE"
          videoTitle="Nid de frelons asiatiques détruit par ABC GUEPES"
        />
      </Box>
      <Box width={[1, 1 / 3]} p={[2, 3, 5]}>
        <Video
          videoSrcURL="https://www.youtube.com/embed/CYkFCxxEvTc"
          videoTitle="Essaimage d abeille ABC GUEPES"
        />
      </Box>
      <Box width={[1, 1 / 3]} p={[2, 3, 5]}>
        <Video
          videoSrcURL="https://www.youtube.com/embed/4GRPB2jP1sI"
          videoTitle="Nid de guêpes en terre ABC Guêpes"
        />
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      {' '}
      <CenterVideo>
        <Box width={[1, 1 / 2]}>
          <Video
            videoSrcURL="https://www.youtube.com/embed/gfo_hlT8oSw"
            videoTitle="Nid de frelons sous une toiture ABC Guêpes"
          />
        </Box>
      </CenterVideo>
      <CenterVideo>
        <Box width={[1, 1 / 2]}>
          <Video
            videoSrcURL="https://www.youtube.com/embed/76ETBBZxHGU"
            videoTitle="Destruction nid de frelons dans un arbre ABC Guêpes"
          />{' '}
        </Box>{' '}
      </CenterVideo>
      <Box width={[1]} p={[2, 2, 2]}>
        <Center>
          <Boutton as={Link} to="/galerie">
            Retour
          </Boutton>
        </Center>
      </Box>
    </Flex>
    <Prefooter />
    <Footer />
  </Layout>
)

export default VideoPage
