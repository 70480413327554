import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Footer from '../components/Footer/Footer'
import Prefooter from '../components/PreFooter/Prefooter'
import Center from '../components/Center'
import Boutton from '../components/Boutton/Boutton'

const MoignyChampigny91150 = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires moigny champigny 91150"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap">
      <Box width={[1, 1]} py={[2, 3, 5]} order={[1, 1]}>
        <Center>
          <h1>Destruction nid guêpes frelons chenilles processionnaires moigny champigny 91150</h1>
          <p>
            Les beaux jours reviennent et avec eux, les guêpes, les frelons et les abeilles!
            <br />
            Ces insectes bien que de petite taille peuvent faire très mal. Qui n'a jamais été piqué
            par ces petits nuisibles ?! Cause de douleur et fièvre leur piqûres peuvent également
            entraîner de plus graves conséquences voir être mortelles en cas d allergie ou de
            piqûres mal placées (bouches,gorges). Personne n'est à l'abri ! Adultes ou enfants ! Il
            ne faut donc pas prendre ce problème à la légère et réagir rapidement en cas de
            suspicion de nid.
            <br />
            De plus ces nids peuvent grossir de manière très rapide et les dégâts occasionnés
            peuvent être énormes notamment lorsque le nid est situé dans une toiture. Ces insectes
            peuvent enlever l'isolation et même aller jusqu'à se frayer un passage à travers le mur
            et donc se propager à l'intérieur de votre habitation. Il y a également les dégâts liés
            au miel dans le cas d'un nid d'abeilles.
            <br />
            Il est donc plus prudent de faire enlever son nid par un spécialiste qui pourra
            intervenir de manière sécurisée et avec le matériel adéquat en fonction du type de nids
            et de ses conditions d'accès.
            <br />
            <br />
            PS: Dans la mesure du possible les nids d'abeilles sont récupérés avec leurs
            pensionnaires.
          </p>
          <br />
          <br />
          <Boutton as={Link} to="/presentation-chenilles-processionnaires">
            Hyménoptères
          </Boutton>
        </Center>
      </Box>
    </Flex>
    <Prefooter />
    <Footer />
  </Layout>
)

export default MoignyChampigny91150
