import styled from 'styled-components'
import { Button } from 'rebass'

const Boutton = styled(Button)`
  color: white;
  background-color: #42e2b8;
  text-decoration: none;
  margin: 4px 2px;
  transition-duration: 0.6s;
  border: 2px solid #42e2b8;
  border-radius: 3em;
  padding: 16px 32px;
  font-weight: bold;
  text-transform: uppercase;
  :hover {
    background-color: transparent;

    color: #42e2b8;

    border: 2px solid #42e2b8;
  }
`
export default Boutton
