import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import {
 Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Center from '../components/Center'
import Boutton from '../components/Boutton/Boutton'
import Underline from '../components/P/Underline'

const ZoneUne = styled(Tr)`
  text-align: center;
`
const ZoneDeux = styled(Tr)`
  color: red;
  text-align: center;
`
const ZoneTrois = styled(Tr)`
  color: green;
  text-align: center;
`
const SpanDeux = styled.span`
  color: red;
`
const SpanTrois = styled.span`
  color: green;
`
const LinkZone1 = styled(Link)`
  text-decoration: none;
  color: black;
`
const LinkZone2 = styled(Link)`
  text-decoration: none;
  color: red;
`
const LinkZone3 = styled(Link)`
  text-decoration: none;
  color: green;
`

const ZoneTarif3 = () => (
  <Layout>
    <Seo
      title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
      encodeSpecialCharacters
      defer={false}
      description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
    />
    <Heading />
    <Flex flexWrap="wrap">
      <Box width={[1, 1]} p={[3, 3, 5]}>
        <h1>Zones tarifaires 94</h1>
        <p>
          ABC GUEPES
          <br />
          Destruction de nid de guêpes, de frelons, d'insectes volants ... Traitement contre les
          chenilles processionnaires.
          <br />
          <Underline>Zone de déplacement</Underline>
          <br />
          Zone 0: Le Chatelet En Brie Zone 1 (- de 50km aller)
          <SpanDeux> Zone 2 (+ de 50km aller) </SpanDeux>
          <SpanTrois> Zone 3 (+ de 80km aller)</SpanTrois>
          <br />
          <br />
          <br />
          Possibilité d'intervenir dans les départements limitrophes.
          <br />
          Liste des communes:&nbsp;
          <Link to="/zones-tarifaires">Seine et Marne 77</Link>
          &nbsp;
          <Link to="/zones-tarifaires-91">Essonne 91</Link>
          &nbsp;Yonne 89 , Loiret 45 , Yvelines 78 , Seine Saint Denis 93
        </p>
      </Box>
    </Flex>
    <Flex flexWrap="wrap">
      <Box width={[1, 1]} p={[3, 3, 4]}>
        <Table>
          <Thead>
            <Tr>
              <Th>Zone</Th>
              <Th>Commune</Th>
              <Th>Code postal</Th>
            </Tr>
          </Thead>
          <Tbody>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-ablon-sur-seine-94480">Ablon-sur-Seine</LinkZone1> </Td>
              <Td> 94480 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-alfortville-94140">Alfortville</LinkZone2> </Td>
              <Td> 94140 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-arcueil-94110">Arcueil</LinkZone2> </Td>
              <Td> 94110 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-boissy-saint-leger-94470">Boissy-Saint-Léger</LinkZone1> </Td>
              <Td> 94470 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-bonneuil-sur-marne-94380">Bonneuil-sur-Marne</LinkZone1> </Td>
              <Td> 94380 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-bry-sur-marne-94360">Bry-sur-Marne</LinkZone2> </Td>
              <Td> 94360 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-cachan-94230">Cachan</LinkZone2> </Td>
              <Td> 94230 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-champigny-sur-marne-94500">Champigny-sur-Marne</LinkZone2> </Td>
              <Td> 94500 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-charenton-le-pont-94220">Charenton-le-Pont</LinkZone2> </Td>
              <Td> 94220 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-chennevieres-sur-marne-94430">Chennevières-sur-Marne</LinkZone1> </Td>
              <Td> 94430 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-chevilly-larue-94550">Chevilly-Larue</LinkZone2> </Td>
              <Td> 94550 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-choisy-le-roi-94600">Choisy-le-Roi</LinkZone1> </Td>
              <Td> 94600 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-creteil-94000">Créteil</LinkZone1> </Td>
              <Td> 94000 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-fontenay-sous-bois-94120">Fontenay-sous-Bois</LinkZone2> </Td>
              <Td> 94120 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-fresnes-94260">Fresnes</LinkZone2> </Td>
              <Td> 94260 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-gentilly-94250">Gentilly</LinkZone2> </Td>
              <Td> 94250 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-ivry-sur-seine-94200">Ivry-sur-Seine</LinkZone2> </Td>
              <Td> 94200 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-joinville-le-pont-94340">Joinville-le-Pont</LinkZone2> </Td>
              <Td> 94340 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-l-hay-les-roses-94240">L'Haÿ-les-Roses</LinkZone2> </Td>
              <Td> 94240 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-la-queue-en-brie-94510">La Queue-en-Brie</LinkZone1> </Td>
              <Td> 94510 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-le-kremlin-bicetre-94270">Le Kremlin-Bicêtre</LinkZone2> </Td>
              <Td> 94270 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-le-perreux-sur-marne-94170">Le Perreux-sur-Marne</LinkZone2> </Td>
              <Td> 94170 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-le-plessis-trevisse-94420">Le Plessis-Trévise</LinkZone1> </Td>
              <Td> 94420 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-limeil-brevannes-94450">Limeil-Brévannes</LinkZone1> </Td>
              <Td> 94450 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-maison-alfort-94700">Maisons-Alfort</LinkZone2> </Td>
              <Td> 94700 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-mandres-les-roses-94520">Mandres-les-Roses</LinkZone1> </Td>
              <Td> 94520 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-marolles-en-brie-94440">Marolles-en-Brie</LinkZone1> </Td>
              <Td> 94440 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-nogent-sur-marne-94130">Nogent-sur-Marne</LinkZone2> </Td>
              <Td> 94130 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-noiseau-94880">Noiseau</LinkZone1> </Td>
              <Td> 94880 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-orly-94310">Orly</LinkZone1> </Td>
              <Td> 94310 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-ormesson-sur-marne-94490">Ormesson-sur-Marne</LinkZone1> </Td>
              <Td> 94490 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-perigny-94520">Périgny</LinkZone1> </Td>
              <Td> 94520 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-rungis-94150">Rungis</LinkZone2> </Td>
              <Td> 94150 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-saint-mande-94160">Saint-Mandé</LinkZone2> </Td>
              <Td> 94160 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-saint-maur-des-fosses-94100">Saint-Maur-des-Fossés</LinkZone1> </Td>
              <Td> 94100 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-saint-maurice-94410">Saint-Maurice</LinkZone2> </Td>
              <Td> 94410 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-santeny-94440">Santeny</LinkZone1> </Td>
              <Td> 94440 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-sucy-en-brie-94370">Sucy-en-Brie</LinkZone1> </Td>
              <Td> 94370 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-thiais-94320">Thiais</LinkZone2> </Td>
              <Td> 94320 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-valenton-94460">Valenton</LinkZone1> </Td>
              <Td> 94460 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-villecresnes-94440">Villecresnes</LinkZone1> </Td>
              <Td> 94440 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-villejuif-94800">Villejuif</LinkZone2> </Td>
              <Td> 94800 </Td>
            </ZoneDeux>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-villeneuve-le-roi-94290">Villeneuve-le-Roi</LinkZone1> </Td>
              <Td> 94290 </Td>
            </ZoneUne>
            <ZoneUne>
              <Td> 1 </Td>
              <Td> <LinkZone1 to="/nid-guepes-frelons-villeneuve-saint-georges-94190">Villeneuve-Saint-Georges</LinkZone1> </Td>
              <Td> 94190 </Td>
            </ZoneUne>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-villiers-sur-marne-94350">Villiers-sur-Marne</LinkZone2> </Td>
              <Td> 94350 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-vincennes-94300">Vincennes</LinkZone2> </Td>
              <Td> 94300 </Td>
            </ZoneDeux>
            <ZoneDeux>
              <Td> 2 </Td>
              <Td> <LinkZone2 to="/nid-guepes-frelons-vitry-sur-seine-94400">Vitry-sur-Seine</LinkZone2> </Td>
              <Td> 94400 </Td>
            </ZoneDeux>
          </Tbody>
        </Table>
        <p>
          ABC Guêpes élimination de nid de guêpes, de frelons, d'insectes volants ...retrait
          enlèvement des chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun
          Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry
        </p>
        <p>
          ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement
          contre les chenilles processionnaires. Seine Saint Denis 93 Loiret 45 Val de Marne 94 Bois
          le Roi Mormant Brie Comte Robert Créteil Orly Vincennes Palaiseau Montargis
        </p>
        <br />
        <Center>
          <Boutton as={Link} to="/tarifs">
            Retour
          </Boutton>

          <br />

          <br />
        </Center>
      </Box>
    </Flex>
  </Layout>
)

export default ZoneTarif3
