import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  text-align: center;
  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
  :hover {
    color: #42e2b8;
  }
`

const NavbarLinks = () => (
  <>
    <NavItem to="/">Accueil</NavItem>
    <NavItem to="/presentation">Présentation</NavItem>
    <NavItem to="/tarifs">Tarifs</NavItem>
    <NavItem to="/galerie">Galerie</NavItem>
    <NavItem to="/contact">Contact</NavItem>
    <NavItem to="/liens-divers">Liens divers</NavItem>
  </>
)

export default NavbarLinks
